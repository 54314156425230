<template>
  <div class="page-container">
    <div ref="BMap" class="map-box"></div>
    <left-list @working-cars="getWorkingCars" @rest-cars="getResetCars" @check-item="checkTransportList">
    </left-list>
    <transition name="right">
      <transport-list
        v-if="transportData.show"
        :name="transportData.name"
        :searchOption="transportData.searchOption"
        @order-check="checkOrder"
      ></transport-list>
    </transition>
  </div>
</template>
<script>
import leftList from "./leftList";
import transportList from "./transportList";
import CarOverlay from "./carOverlay";
import moment from "moment";
// import axios from "axios";
import { BMAP_AK, SERVICE_ID } from "@/common/constants";
import { yingyanHost } from "@/common/http/host";
import { jsonp } from "@/common/util";
import dayjs from 'moment'
export default {
  components: { leftList, transportList },
  data() {
    return {
      map: null,
      convertor: null,
      // 运输记录组件需要数据
      transportData: {
        show: false,
        name: "",
        searchOption: {},
      },
      // 当前查询的订单记录
      currentOrder: null,
      // 显示即时运输车辆
      workingCars: [],
      // 即时更新车辆位置循环
      workingCarsPoistionFlashInterval: null,
      // 即时显示车辆的覆盖物集合
      workingCarsMarks: [],
      // 订单轨迹覆盖物
      currentOrderPolyline: null,
      // 订单起点
      currentStartMarker: null,
      // 订单终点
      currentEndMarker: null,
    };
  },
  computed: {},
  watch: {
    currentOrder(order) {
      this.clearCarLine();
      if (order) {
        this.clearWorkingCarsMarker();
      } else {
        this.getAllPosition(this.workingCars).then((entities) => {
          this.clearWorkingCarsMarker();
          let points = [];
          entities.forEach((item) => {
            let point = new BMapGL.Point(
              item.latest_location.longitude,
              item.latest_location.latitude
            );
            points.push(point);
            this.addCarOverlay(item.latest_location).then((marker) =>
              this.workingCarsMarks.push(marker)
            );
          });
          points.length > 0 && this.map.setViewport(points);
        });
      }
    },
    workingCars: {
      handler(list) {
        if (this.workingCarsPoistionFlashInterval)
          clearInterval(this.workingCarsPoistionFlashInterval);
        if (list && list.length > 0) {
          this.workingCarsPoistionFlashInterval = setInterval(() => {
            if (this.currentOrder) return;
            this.getAllPosition(list).then((entities) => {
              this.clearWorkingCarsMarker();
              let points = [];
              entities.forEach((item) => {
                let point = new BMapGL.Point(
                  item.latest_location.longitude,
                  item.latest_location.latitude
                );
                points.push(point);
                this.addCarOverlay(item.latest_location).then((marker) =>
                  this.workingCarsMarks.push(marker)
                );
              });
              points.length > 0 && this.map.setViewport(points);
            });
          }, 10 * 1000);
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 初始化地图
    initMap() {
      return new Promise((resolve) => {
        this.map = new BMapGL.Map(this.$refs.BMap);
        let point = new BMapGL.Point(105.52314042411466, 29.475651579673745);
        this.map.centerAndZoom(point, 11);
        this.map.enableScrollWheelZoom();
        this.map.disablePinchToZoom();
        resolve();
      });
    },
    // 清空实时车辆覆盖物
    clearWorkingCarsMarker() {
      while (this.workingCarsMarks.length > 0) {
        let temp = this.workingCarsMarks[0];
        this.map.removeOverlay(temp);
        this.workingCarsMarks.splice(0, 1);
      }
    },
    // 清空路线
    clearCarLine() {
      if (this.currentOrderPolyline) {
        this.map.removeOverlay(this.currentOrderPolyline);
        this.currentOrderPolyline = null;
      }
      if (this.currentStartMarker) {
        this.map.removeOverlay(this.currentStartMarker);
        this.currentStartMarker = null;
      }
      if (this.currentEndMarker) {
        this.map.removeOverlay(this.currentEndMarker);
        this.currentEndMarker = null;
      }
    },
    // 批量获取坐标点
    getAllPosition(list) {
      return new Promise((resolve) => {
        let formData = new FormData();
        formData.append("ak", BMAP_AK);
        formData.append("service_id", SERVICE_ID);
        formData.append(
          "filter",
          "entity_names:" + list.map((item) => item.carName).join(",")
        );
        let url = `${yingyanHost}/entity/list?ak=${BMAP_AK}&service_id=${SERVICE_ID}&filter=entity_names:${list
          .map((item) => item.carName)
          .join(",")}`;
        jsonp(url, (res) => {
          if (res.status === 0) {
            resolve(res.entities);
          } else {
            resolve([]);
          }
        });
        // axios.get(url).then();
      });
    },
    // 添加地图点击时间
    addMapClickEvent() {
      this.map.addEventListener("click", (e) => {
        let lat = e.point.lat;
        let lng = e.point.lng;
        let pointList = [
          {
            entity_name: "渝C88888",
            latitude: lat,
            longitude: lng,
            coord_type_input: "bd09ll",
            loc_time: Math.floor(new Date().getTime() / 1000),
          },
        ];
        this.$post(this.$api.YINGYAN.ADD_POINTS, {
          pointList,
        }).then((res) => {
          console.log(res);
        });
      });
    },
    // 转换坐标
    convertPoint({ longitude, latitude }) {
      return new Promise((resolve) => {
        if (!this.convertor) this.convertor = new BMapGL.Convertor();
        this.convertor.translate(
          [new BMapGL.Point(longitude, latitude)],
          1,
          5,
          (data) => {
            if (data.status === 0) {
              resolve(data);
            }
          }
        );
      });
    },
    // 添加车辆
    addCarOverlay({ longitude, latitude }) {
      return new Promise((resolve) => {
        console.log(longitude);
        let point = new BMapGL.Point(longitude, latitude);
        let carOverlay = new CarOverlay(point);
        this.map.addOverlay(carOverlay);
        // this.map.panTo(point);
        resolve(carOverlay);
      });
    },
    // 查看订单列表
    checkTransportList(data) {
      if (!data) {
        this.transportData.show = false;
        this.transportData.name = "";
        this.transportData.imei = ''
        this.transportData.searchOption = {};
        this.currentOrder = null;
        return;
      }
      switch (data.type) {
        case "car":
          this.transportData.name = data.data.carPlate;
          this.transportData.imei = data.data.imei
          this.transportData.searchOption = {
            plate: data.data.carPlate,
          };
          this.transportData.show = true;
          if (data.data.imei) {
            this.$post('jimi/location', {
              imei: data.data.imei
            }).then(r => {
              if (r.result && r.result.length > 0) {
                let result = r.result[0]
                this.addCarOverlay({longitude:result.lng, latitude:result.lat})
              }
            })
          }
          break;
        case "driver":
          this.transportData.name = data.data.driverName;
          this.transportData.searchOption = {
            driverId: data.data.driverUserId,
          };
          this.transportData.show = true;
          break;
        case "order":
          this.transportData.name = data.data.carName;
          this.transportData.searchOption = {
            plate: data.data.carName,
          };
          this.transportData.show = true;
          break;
      }
    },
    // 查看订单详情（查询车辆运行记录)
    checkOrder(order) {
      this.currentOrder = order;
      console.log(order);
      if (!order) return;
      new Promise((resolve, reject) => {
        let end = order.endTime ? moment(order.endTime) : moment();
        let timeUnit = moment(order.startTime);
        let start = timeUnit.unix();
        let count = 0;
        let finishCount = 0;
        let points = [];
        let index = 0;
        let callback = () => {
          if (finishCount === count) {
            let results = [];
            for (let i = 0; i < points.length; i++) {
              results.push(...points[i]);
            }
            resolve(results);
          }
        };
        do {
          timeUnit.add(8, "hours");
          count++;
          let tempIndex = index;
          this.getTrackData(order.carName, start, timeUnit.unix()).then(
            (list) => {
              points[tempIndex] = list;
              finishCount++;
              callback();
            }
          );
          index++;
          start = timeUnit.unix();
        } while (timeUnit.isBefore(end));
      }).then((points) => {
        let pointArr = points.map(
          (item) => new BMapGL.Point(item.lng, item.lat)
        );
        let startIcon = new BMapGL.Icon(
          "/imgs/map/start.png",
          new BMapGL.Size(25, 30)
        );
        let endIcon = new BMapGL.Icon(
          "/imgs/map/end.png",
          new BMapGL.Size(25, 30)
        );
        let startMarker = new BMapGL.Marker(pointArr[0], {
          icon: startIcon,
          offset: new BMapGL.Size(0, -15),
        });
        let endMarker = new BMapGL.Marker(pointArr[pointArr.length - 1], {
          icon: endIcon,
          offset: new BMapGL.Size(0, -15),
        });
        let Polyline = new BMapGL.Polyline(pointArr, {
          strokeColor: "#06baa1",
          strokeWeight: 4,
        });
        this.map.addOverlay(Polyline);
        this.map.addOverlay(startMarker);
        // this.map.addOverlay(endMarker);
        this.map.setViewport(pointArr);
        let car = pointArr[pointArr.length - 1]
        this.addCarOverlay({ longitude: car.lng, latitude:car.lat })
        this.currentOrderPolyline = Polyline;
        this.currentStartMarker = startMarker;
        this.currentEndMarker = endMarker;
      });
    },
    // 查询轨迹数据
    getTrackData(entityName, startTime, endTime, pageNo = 1) {
      let url = `${yingyanHost}/track/gettrack?ak=${BMAP_AK}&service_id=${SERVICE_ID}&entity_name=${entityName}&start_time=${startTime}&end_time=${endTime}&is_processed=1&process_option=denoise_grade=3,need_mapmatch=1,transport_mode=driving,vacuate_grade=0&supplement_mode=driving&supplement_content=distance_and_points&page_index=${pageNo}&page_size=500`;
      return new Promise((resolve) => {
        this.$post(this.$api.TRAJECTORY, {
          entity_name: entityName,
          start_time: startTime,
          end_time: endTime,
          beginTime:dayjs.unix(startTime).format('YYYY-MM-DD HH:mm:ss'),
          endTime:dayjs.unix(endTime).format('YYYY-MM-DD HH:mm:ss'),
          imei: this.transportData.imei,
          is_processed: "1",
          process_option:
            "denoise_grade=3,need_mapmatch=1,transport_mode=driving,vacuate_grade=0",
          supplement_mode: "driving",
          supplement_content: "distance_and_points",
          page_index: pageNo,
          page_size: 500,
        }).then((res) => {
          console.log(res);
          // let total = +res.total;
          // let list = res.points;
          // if (pageNo * 500 < total) {
          //   this.getTrackData(entityName, startTime, endTime, pageNo + 1).then(
          //     (subList) => {
          //       let result = [...list, ...subList];
          //       resolve(result);
          //     }
          //   );
          // } else {
          //   resolve(list);
          // }
          resolve(res)
        });
        // jsonp(url, (res) => {
        //   let total = +res.total;
        //   let list = res.points;
        //   if (pageNo * 500 < total) {
        //     this.getTrackData(entityName, startTime, endTime, pageNo + 1).then(
        //       (subList) => {
        //         let result = [...list, ...subList];
        //         resolve(result);
        //       }
        //     );
        //   } else {
        //     resolve(list);
        //   }
        // });
      });
    },
    // 工作中的车辆
    getWorkingCars(cars) {
      this.workingCars = cars;
    },
    // 获取休息中的车辆
    getResetCars(cars) {
      if (Array.isArray(cars)) {
        cars.map(r => {
          if (r.lng && r.lat) {
            this.addCarOverlay({
              longitude: r.lng,
              latitude: r.lat,
            })
          }
        })
      }
    }
  },
  beforeDestroy() {
    this.workingCarsPoistionFlashInterval &&
      window.clearInterval(this.workingCarsPoistionFlashInterval);
  },
  mounted() {
    this.initMap().then(() => {
      // this.addMapClickEvent();
    });
    // this.getLastPoint("渝C88888").then((res) => this.addCarOverlay(res));
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>
<style>
.right-enter-active {
  animation: right-in-animation 0.5s;
}
.right-leave-active {
  animation: right-in-animation 0.5s reverse;
}
@keyframes right-in-animation {
  0% {
    width: 0px;
  }
  50% {
    width: 120px;
  }
  100% {
    width: 240px;
  }
}
</style>
